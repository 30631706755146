<template>
	<section>
		<b-form-input
			:required="required"
			class="input mb-2 form-control"
			type="date"
			v-model="date"
			:placeholder="$t('global.startDate')"
			:disabled="disabled"
			:state="isRequiredAndFilled"
		/>
	</section>
</template>

<script>
import requiredFieldMixin from '../../../mixins/requiredFieldMixin'

export default {
	name: 'InputDate',
	props: ['value', 'disabled', 'required'],
	mixins: [requiredFieldMixin],
	computed: {
		date: {
			get() {
				if (this.value) {
					if (this.value instanceof Date) {
						return this.value.toISOString().split('T')[0]
					} else {
						return this.value
					}
				} else {
					return null
				}
			},
			set(val) {
				console.log('Emitted')
				this.$emit('input', val)
			},
		},
	},
}
</script>
